<template>
    <div class="chat" id="portal-chatbox">
        <h2 class="no_chat" v-if="!chatLogs.length">
            <img src="@/assets/images/empty_chat.svg">
            You haven't started any chat with this contact yet.
        </h2>
        <infinite-loading v-if="initInfiniteLoader && contact.id" class="infinite-loading" @infinite="handleChatLogs" target=".chat" :identifier="resetInfiniteWatcher" :top="true" ref="portal-chat-box-infinite-loading" />
        <div v-for="(chatLog, c) in chatLogs.slice().reverse()" :key="c" >
            <div v-html="getDaylabel(chatLog.created_at, c)"></div>
            <div class="chat_client" v-if="chatLog.send_by == 0">
                <span class="option">
                    <i class="fas fa-ellipsis-v"></i>
                    <ul>
                        <li><label>Created :</label><span>{{ moment.utc(chatLog.created_at).local().format('ll | LT') }}</span></li>
                        <li><label>Sent :</label><span>{{ moment.utc(chatLog.created_at).local().format('ll | LT') }}</span></li>
                        <li><label>Delivered :</label><span>{{ moment.utc(chatLog.created_at).local().format('ll | LT') }}</span></li>
                    </ul>
                </span>
                <div class="msg">
                    <p v-if="chatLog.message"><pre v-html="parseURLs(chatLog.message)"></pre></p>
                    <div v-if="chatLog.media">
                        <div class="media_view" v-for="(image, m) in chatLog.media" :key="m" v-html="image ? getFileIconByUrl(image.url ? image.url : image, uniqueId, image.type ? image.type : '' ) : void(0)"></div>
                    </div>
                    <span>{{ moment.utc(chatLog.created_at).local().fromNow() }}</span>
                </div>
                <img :src="chatLog.employer && chatLog.employer.profile_pic ? chatLog.employer.profile_pic : require('@/assets/images/male-female.png')" @error="replaceByDefault">
            </div>
            <div class="chat_user" v-else>
                <img :src="contact.photo ? contact.photo : require('@/assets/images/male-female.png')" @error="replaceByDefault">
                <div class="msg">
                    <p v-if="chatLog.message"><pre v-html="parseURLs(chatLog.message)"></pre></p>
                    <div v-if="chatLog.media">
                        <div class="media_view" v-for="(image, m) in chatLog.media" :key="m" v-html="image ? getFileIconByUrl(image.url ? image.url : image, uniqueId, image.type ? image.type : '' ) : void(0)"></div>
                    </div>
                    <span>{{ moment.utc(chatLog.created_at).local().fromNow() }}</span>
                </div>
                <span class="option">
                    <i class="fas fa-ellipsis-v"></i>
                    <ul>
                        <li><label>Created :</label><span>{{ moment.utc(chatLog.created_at).local().format('ll | LT') }}</span></li>
                        <li><label>Sent :</label><span>{{ moment.utc(chatLog.created_at).local().format('ll | LT') }}</span></li>
                        <li><label>Delivered :</label><span>{{ moment.utc(chatLog.created_at).local().format('ll | LT') }}</span></li>
                    </ul>
                </span>
            </div>
        </div>
    </div>
    <div class="chat_box">
        <!-- <button type="button" @click="handleCreateSchedule" class="hideOnTinyScreens">
            <img src="@/assets/images/calendar-alt.svg" alt="">
        </button> -->
        <div class="input_area" :class="{ 'input_area_error' : countError }">
            <textarea rows="10" v-model="message" placeholder="Type Your Message.." id="portal-message-textbox" @click="handleMarkRead()"></textarea>
            <button type="button" @click="attachment = true" class="hideOnTinyScreens">
                <i class="fas fa-paperclip"></i>
            </button>
            <div class="hideOnTinyScreens dropdown personalize" @click="personalizeOption = !personalizeOption" v-click-outside="closePersonalizeOption">
                <i class="fas fa-user"></i>
                <div class="drp_wrapper" v-if="personalizeOption" :class="{ active : personalizeOption }">
                    <ul>
                        <li v-for="(personalize, p) of defaultPersonalize" @click="insertPersonalize(personalize)" :key="p">{{ personalize }}</li>
                    </ul>
                </div>
            </div>
            <emoji-picker :onSelectEmoji="insertEmoji" classes="chat-emoji-picker hideOnTinyScreens" />
            <button :disabled="sendMessageLoader" id="send-message-button" type="button" class="hideOnTinyScreens post" @click="handleSendMessage">
                <i class="fas fa-spin fa-spinner" v-if="sendMessageLoader"></i>
                <i class="fas fa-paper-plane" v-else></i>
            </button>
            <button type="button" @click="handleCreateSchedule" class="hideOnTinyScreens">
                <img src="@/assets/images/calendar-alt.svg" alt="">
            </button>
            <span class="hideOnTinyScreens counter">{{ message.length }} C | {{ message.length ? message.split(/\s+/).length : 0 }} W</span>
            <span class="hideOnTinyScreens media_area mt-1" v-show="media">
                <label>{{ mediaFile.name }} ({{ bytesToSize(mediaFile.size) }})</label> <i class="fa fa-times pointer text-danger" @click="media = ''"></i>
            </span>
        </div>
    </div>
    <div class="chat_box onTinyScreen">
      <button type="button" @click="handleCreateSchedule" class="schedule_btn">
        <img src="@/assets/images/calendar-alt.svg" alt="">
      </button>
      <span class="media_area mt-1" v-show="media">
                  <label>{{ mediaFile.name }} ({{ bytesToSize(mediaFile.size) }})</label> <i
          class="fa fa-times pointer text-danger" @click="media = ''"></i>
              </span>
      <div class="input_area" :class="{ 'input_area_error' : countError }">
        <button type="button" @click="attachment = true">
          <i class="fas fa-paperclip"></i>
        </button>
        <div class="dropdown" @click="personalizeOption = !personalizeOption">
          <i class="fas fa-user"></i>
          <div class="drp_wrapper" v-if="personalizeOption" :class="{ active : personalizeOption }">
            <ul>
              <li v-for="(personalize, p) of defaultPersonalize" @click="insertPersonalize(personalize)" :key="p">
                {{ personalize }}
              </li>
            </ul>
          </div>
        </div>
        <emoji-picker :onSelectEmoji="insertEmoji" classes="chat-emoji-picker"/>
        <button :disabled="sendMessageLoader" id="send-message-button" type="button" class="post"
                @click="handleSendMessage">
          <i class="fas fa-spin fa-spinner" v-if="sendMessageLoader"></i>
          <i class="fas fa-paper-plane" v-else></i>
        </button>
      </div>
    </div>
    <div class="modal" v-if="attachment" :class="{ active : attachment }" @click.self="attachment = false">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>Attach Media</h1>
                </div>
                <button class="close_btn left_out" @click="attachment = false"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="setting_wpr">
                    <div class="upload_image">
                        <div :id="`dz-${uniqueId}`" class="dropzone" :class="{ 'dropzone-error' : fileError }">
                            <div class="dz-message" data-dz-message>
                                <div>
                                    <img src="@/assets/images/image2.png">
                                    <h4>Click to Upload Content</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal_footer">
                <button type="button" class="btn cancel_btn" @click="attachment = false; media = '';">Cancel</button>
                <button type="button" class="btn save_btn" @click="attachment = false">Attach</button>
            </div>
        </div>
    </div>
    <create-schedule v-model="createSchedule" :contact-id="contact.id" :message="message" :media="media" />
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import { uuid } from 'vue-uuid';
    import { defineAsyncComponent } from 'vue'

    const EmojiPicker = defineAsyncComponent(() => import('@/components/EmojiPicker'))
    const CreateSchedule = defineAsyncComponent(() => import('@/pages/sms-center/portal-chat/CreateSchedule'))

    import InfiniteLoading from 'v3-infinite-loading'
    import moment from 'moment'
    import Helper from '@/utils/Helper'
    import Dropzone from 'dropzone'
    import 'dropzone/dist/dropzone.css'
    import constants from '@/utils/Constant'

    export default {
        name: 'Portal Chat Box',

        data () {
            return {
                personalizeOption: false,
                attachment: false,
                params: {
                    per_page: 5,
                    page: 1,
                    last_page: 1,
                    search: '',
                    filter: '',
                    contact_id: ''
                },
                state: undefined,
                loader: false,
                chatLogs: [],
                resetInfiniteWatcher: 0,
                moment,
                uniqueId: 'id'+uuid.v4(),
                message: '',
                media: '',
                mediaFile: '',
                source: 'sms chat',
                countError: false,
                fileError: false,
                createSchedule: false,
                defaultPersonalize: [],
                initInfiniteLoader: false,
                textFieldClickCount: 0,
            }
        },

        props: {
            contact: Object,
            filter: {
                type: String,
                default: 'all',
            },
            isContact: {
                type: Boolean,
                default: false,
            },
            updateContactList: Function,
        },

        watch: {
            contact (contact) {
                const vm = this;

                if (contact.id) {
                    vm.resetForm();
                }
            },

            attachment (attachment) {
                const vm = this;

                vm.fileError = false;

                if (attachment) {
                    setTimeout(function () {
                        vm.initDropzone();
                    }, 100);
                }
            },

            message (val) {
                const vm =  this;

                if (val && vm.countError) {
                    vm.countError = false;
                }
            },

            receiveChatLogWatcher (val) {
                const vm = this;

                vm.textFieldClickCount = 0;

                setTimeout(function () {
                    if (vm.contact.id == vm.receiveChatLog.contact_id && vm.contact.id == vm.receiveChatLog.sender_id) {
                        vm.chatLogs.unshift(vm.receiveChatLog);

                        setTimeout(function () {
                            const chatbox = document.getElementById('portal-chatbox');
                            if (chatbox) {
                                chatbox.scrollTop  = chatbox.scrollHeight;
                            }
                        }, 100);
                    }
                }, 100);
            },
        },

        components: {
            EmojiPicker,
            InfiniteLoading,
            CreateSchedule,
        },

        computed: {
            ...mapState({
                user: state => state.authModule.user,
                authToken: state => state.authModule.authToken,
                sendMessageLoader: state => state.portalChatModule.sendMessageLoader,
                receiveChatLogWatcher: state => state.portalChatModule.receiveChatLogWatcher,
                receiveChatLog: state => state.portalChatModule.receiveChatLog,
            }),
        },

        mounted () {
            const vm = this;

            vm.defaultPersonalize = constants.personalize.concat(vm.personalize);

            // if (vm.contact.id) {
            //     vm.resetForm();
            // }

            document.getElementById('portal-chatbox').addEventListener('scroll', event => {
                if (event.target.scrollTop == 0 && !vm.loader) {
                    vm.initInfiniteLoader = true;
                }
            });

            document.getElementById("portal-message-textbox").addEventListener('keypress', event => {
                if (event.keyCode == 13 && event.shiftKey) {
                } else if (event.key === 'Enter') {
                    event.preventDefault();

                    document.getElementById("send-message-button").click();
                }
            });
        },

        methods: {
            ...mapActions({
                getPortalChatLogs: 'portalChatModule/getPortalChatLogs',
                sendMessage: 'portalChatModule/sendMessage',
                updateMarkAsReadUnread: 'portalChatModule/updateMarkAsReadUnread',
                getStats: 'portalChatModule/getStats',
            }),

            insertEmoji (emoji) {
                const vm = this;
                const textarea    = document.getElementById('portal-message-textbox');
                const caretPos    = textarea.selectionStart;
                const textAreaTxt = textarea.value;
                const txtToAdd    = emoji;

                vm.message = textAreaTxt.substring(0, caretPos) + ' ' +  txtToAdd + ' '  + textAreaTxt.substring(caretPos);
            },

            insertPersonalize (personalize) {
                const vm = this;
                const textarea    = document.getElementById('portal-message-textbox');
                const caretPos    = textarea.selectionStart;
                const textAreaTxt = textarea.value;
                const txtToAdd    = `{{ ${ personalize.toLowerCase().replaceAll(' ', '_') } }}`;

                vm.message = textAreaTxt.substring(0, caretPos) + ' ' +  txtToAdd + ' '  + textAreaTxt.substring(caretPos);
            },

            async handleChatLogs ($state, force = false) {
                const vm = this;

                if ($state) {
                    vm.state = $state;
                }

                if (!vm.contact.id) {
                    if (vm.state != undefined) {
                        vm.state.complete();
                    }
                    vm.chatLogs = [];
                } else {
                    if ((vm.params.page <= vm.params.last_page ) || force) {
                        if (!vm.loader) {
                            vm.loader = true;

                            await vm.getPortalChatLogs(vm.params).then((result) => {
                                if (result.data && result.data.length) {
                                    vm.params.last_page = result.last_page;
                                    vm.params.page      = result.current_page + 1;
                                    vm.chatLogs.push(...result.data);
                                    vm.loader = false;

                                    if (vm.state != undefined) {
                                        vm.state.loaded();
                                    }

                                    if (result.current_page == 1 && vm.chatLogs.length == result.data.length) {
                                        setTimeout(function () {
                                            const chatbox      = document.getElementById('portal-chatbox');
                                            chatbox.scrollTop  = chatbox.scrollHeight;
                                        }, 100);
                                    }
                                } else {
                                    if (vm.state != undefined) {
                                        vm.state.complete();
                                    }

                                    vm.loader = false;
                                }
                            });
                        }
                    } else {
                        if (vm.state) {
                            vm.state.complete();
                        }
                    }
                }
            },

            resetForm () {
                const vm = this;
                vm.initInfiniteLoader = false;

                vm.chatLogs = [];
                vm.params = {
                    per_page: 5,
                    page: 1,
                    last_page: 1,
                    search: '',
                    filter: vm.filter,
                    contact_id: vm.contact.id
                };

                vm.message     = '';
                vm.media       = '';
                vm.textFieldClickCount = 0;

                setTimeout(function () {
                    vm.resetInfiniteWatcher++;
                    vm.handleChatLogs(undefined, true);
                }, 100);
            },

            getFileIconByUrl (image, uniqueId, contentType = '') {
                return Helper.getFileIconByUrl(image, uniqueId, contentType);
            },

            getDaylabel (date, key) {
                const vm    = this;
                const day   = moment.utc(date).local().format('ll');
                const today = moment().local().format('ll');

                if (key == 0 || vm.daycheck != day) {
                    vm.daycheck = day;

                    if (vm.daycheck == today) {
                        return `<div class="timeline"><span>Today</span></div>`;
                    } else if (vm.daycheck == (today - 1)) {
                        return `<div class="timeline"><span>Yesterday</span></div>`;
                    } else {
                        return `<div class="timeline"><span>${vm.daycheck}</span></div>`;
                    }
                } else {
                    return void(0);
                }
            },

            handleSendMessage() {
                const vm = this;

                if (vm.message.length || vm.media) {
                    vm.countError = false;
                } else {
                    return vm.countError = true;
                }

                const params = {
                    message: vm.message,
                    media: vm.media ? [vm.media] : [],
                    receiver_id: vm.contact.id,
                }

                vm.sendMessage(params).then((result) => {
                    if (result) {
                        vm.message      = '';
                        vm.media        = '';
                        result.employer = { profile_pic: vm.user ? vm.user.profile_pic : '' };
                        vm.chatLogs.unshift(result);

                        // if (!vm.isContact) {
                        //     vm.updateContactList(undefined, true);
                        // }

                        setTimeout(function () {
                            const chatbox = document.getElementById('portal-chatbox');
                            if (chatbox) {
                                chatbox.scrollTop  = chatbox.scrollHeight;
                            }
                        }, 100);
                    }
                });
            },

            initDropzone () {
                const vm = this;

                const dropzone = new Dropzone(`#dz-${vm.uniqueId}`, {
                    url: process.env.VUE_APP_API_URL+'/api/v3/content-setting/file-upload',
                    autoProcessQueue: true,
                    maxFiles: 1,
                    acceptedFiles: '.jpg, .jpeg, .png, .mp3, .mp4, .pdf',
                    maxFilesize: 4.5,
                    paramName: 'file[]',
                    createImageThumbnails: true,
                    addRemoveLinks: true,
                    headers: {
                        'Authorization': 'Bearer '+vm.authToken.access_token,
                        'accessed-location-id': vm.authToken.accessed_location_id,
                        'company-admin-id': vm.authToken.company_admin_id,
                    },

                    init: function () {
                        this.on("success", function(file, response) {
                            vm.mediaFile  = file;
                            vm.media      = response.url;
                            vm.fileError  = false;
                        });

                        this.on("error", function(file, errorMessage) {
                            vm.fileError = true;
                        });
                    },

                    removedfile: function (file) {
                        vm.media = '';
                        var ref  = file.previewElement;
                        return ref != null ? ref.parentNode.removeChild(file.previewElement) : void 0;
                    },
                })
            },

            handleCreateSchedule () {
                const vm = this;
                if (vm.message.length || vm.media) {
                    vm.countError     = false;
                    vm.createSchedule = true;
                } else {
                    vm.countError = true;
                }
            },

            bytesToSize (bytes) {
                const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

                if (bytes == 0) {
                    return '0 Byte';
                }

                const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));

                return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
            },

            handleMarkRead () {
                const vm = this;

                const params = {
                    value: 0,
                    contact_id: vm.contact.id,
                }

                if (vm.textFieldClickCount < 1 ) {
                    vm.textFieldClickCount++;
                    vm.updateMarkAsReadUnread(params).then((result) => {
                        if (result) {
                            vm.getStats();
                        }
                    });
                }
            },

            replaceByDefault(e) {
                e.target.src = require('@/assets/images/male-female.png');
            },

            closePersonalizeOption(){
                const vm = this;
                vm.personalizeOption = false;
            },

            parseURLs(text) {
                const urlRegex = /(https?:\/\/[^\s]+)/g;

                return text.replace(urlRegex, function(url) {
                    return '<a href="' + url + '" target="_blank" style="color: #121525">'  + url + '</a>';
                })
            }
        }
    }
</script>

<style scoped>
    .onTinyScreen {
      display: none;
    }
    @media screen and (max-width: 399px) {
      /* .onTinyScreen {
        display: flex;
      }
      .hideOnTinyScreens {
        display: none;
      } */
      .chat_box.onTinyScreen .input_area {
        justify-content: right;
      }
      .chat_box.onTinyScreen .media_area label {
        font-size: 11px;
        line-height: 14px;
        color: #2f7fed;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
    :deep(.chat-emoji-picker .v3-input-picker-wrap) {
        width: 40px;
        height: 40px;
    }

    :deep(.chat-emoji-picker .v3-input-picker-wrap .v3-input-picker-icon) {
        width: 28px;
        height: 18px;
    }

    .chat_box .input_area_error {
        border: 1px solid #eb1414 !important;
    }

    h2.no_chat{
        font-size: 20px;
        line-height: 30px;
        max-width: 350px;
        text-align: center;
        color: #999;
        font-weight: 300;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    h2.no_chat img{
        display: block;
        max-width: 120px;
        height: auto;
        margin: 0 auto 20px auto;
    }
    .dropzone {
        border: 0;
        background: #fff;
        min-height: 150px;
    }
    .upload_image .dz-message img{
        max-width: 50px;
    }

    :deep(.dz-message) {
        vertical-align: middle;
        margin: auto;
        display: grid;
        height: 100%;
        min-height: 150px;
    }

    :deep(.dz-message div) {
        margin: auto;
    }

    :deep(.dropzone .dz-preview .dz-image) {
        border-radius: 20px;
        overflow: hidden;
        width: 100%;
        height: 100%;
        position: relative;
        display: block;
        z-index: 10;
    }

    .file-error {
        float: left;
        color: #eb1414;
    }

    .dropzone-error {
        border: 1.2px dashed #eb1414;
    }
    .chat_box .dropdown.personalize{
        z-index: 2;
    }
    .chat_box .dropdown .drp_wrapper{
        left: 25px;
        bottom: 0;
        right: auto;
        margin: 0;
        text-align: left;
    }

    .chat_box .input_area .media_area {
        position: absolute;
        right: 0;
        top: 100%;
        margin-top: 5px;
        background-color: #f0f0f0;
        border: 1px solid transparent;
        font-weight: bold;
        margin: 0 0 9px;
        overflow-y: hidden;
        padding: 4px 25px 4px 8px;
        border-radius: 11px;
        text-align: left;
    }
    .chat_box .input_area .media_area label{
        font-size: 11px;
        line-height: 14px;
        color: #2f7fed;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .chat_box .input_area .media_area i{
        position: absolute;
        right: 8px;
        top: 3px;
        padding: 0;
    }

    .msg .img-thumbnail {
        width: auto;
        height: 100px;
        border-radius: 10px;
        object-fit: cover;
        margin-left: 10px;
    }

    .media_view {
        margin-top: 10px;
    }

    :deep(.media_view video) {
        width: 200px;
    }

    :deep(.media_view img) {
        width: auto;
        height: 100px;
        border-radius: 10px;
        object-fit: cover;
        margin-left: 10px;
    }

    .infinite-loading {
        margin-top: 0;
        margin-bottom: 30px;
    }

    .msg p{
        word-break: break-word !important;
    }
</style>
